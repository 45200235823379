import React, { useState, useEffect } from 'react';
import './App.css';

interface Message {
  text: string;
  isUser: boolean;
}

interface Translation {
  japanese: string;
  english: string;
}

const translations: Translation[] = [
  { japanese: "たくさん残業をする必要があります。", english: "I need to do lots of overtime." },
  { japanese: "たくさんの責任を抱えています。", english: "I have a lot of responsibility." },
  { japanese: "人々の問題を解決する必要があります。", english: "I need to deal with people's problems." },
  { japanese: "自分自身のプロジェクトにとりかかる時間がありません。", english: "There's no time to work on my own projects." },
  { japanese: "会議の為に膨大な時間を割いています。", english: "I spend so much time in meetings." },
  { japanese: "私たちの決断について意見を言います。", english: "I get a say in what we decide." },
  { japanese: "よくストレスをためています。", english: "I often get stressed out." },
  { japanese: "給料の良い仕事です。", english: "It's a well-paid job." },
  { japanese: "仕事上の問題について話す", english: "Talk about work-related issues." },
  { japanese: "私達はよく結婚ごっこをして遊んだのを覚えていますか？", english: "Remember when we would make believe that we were married?" },
  { japanese: "あの頃が懐かしい。夏中泳いだものです！", english: "Those were the days. I'd swim all summer!" },
  { japanese: "あの頃はもっと素朴な生活だった。", english: "Life was simpler back then." },
  { japanese: "ゲールの最初の１歩は彼女がまだ10ヶ月の赤ちゃんの時でした。", english: "Gale took her first step when she was 10 months old." }
];

const App: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [userInput, setUserInput] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    addMessage(translations[currentIndex].japanese, false);
  }, [currentIndex]);

  const addMessage = (text: string, isUser: boolean) => {
    setMessages(prev => [...prev, { text, isUser }]);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    addMessage(userInput, true);

    if (userInput.toLowerCase().trim() === translations[currentIndex].english.toLowerCase()) {
      addMessage("正解です！次の問題に進みましょう。", false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % translations.length);
      }, 1500);
    } else {
      addMessage(`不正解です。正解は: ${translations[currentIndex].english}`, false);
    }

    setUserInput('');
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>英語翻訳チャットクイズ</h1>
      </header>
      <div className="chat-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'message-user' : 'message-system'}`}>
            <span className="message-content">
              {message.text}
            </span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={userInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInput(e.target.value)}
          placeholder="英語で入力してください"
          className="input-field"
        />
        <button type="submit" className="submit-button">送信</button>
      </form>
    </div>
  );
};

export default App;